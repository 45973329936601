import React from 'react';
import styled from 'styled-components';

import { BlogPostPreviewList } from 'components/blog';
import {
    TABLET_GRID_SIZE,
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING
} from 'consts';

import content from 'data/thanks.json';
import { MediaQueries, Sizes, Colors } from 'environment';
import { useQueryContext, useMediaQuery } from 'hooks';

interface Props {
    title: string;
    category: string;
}

export default function Thanks({ title }: Props) {
    const { posts } = useQueryContext();
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);

    const numberOfImages = isPhone ? 1 : isTablet ? 2 : 3;

    return (
        <Container>
            <Content>
                <Title>{content.title}</Title>
                <Description>{content.text}</Description>
                <Posts>
                    <PostsList
                        posts={posts.filter((e) => e.title !== title).slice(0, numberOfImages)}
                        title={isPhone ? 'Latest article' : 'Latest articles'}
                    ></PostsList>
                </Posts>
            </Content>
        </Container>
    );
}

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12rem;
    color: ${Colors.violet};

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        margin-top: 6rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        align-items: center;
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
        padding: 0 2rem;
    }
`;

const Posts = styled.div`
    width: 100%;
`;

const Title = styled.h1`
    margin-bottom: 2rem;
    text-align: center;

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
    }
`;

const Description = styled.h4`
    font-weight: normal;
    max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
    text-align: center;
`;

const PostsList = styled(BlogPostPreviewList)`
    padding-top: 8rem;

    h3 {
        line-height: 3.8rem;
        color: ${Colors.violet};
    }

    @media ${MediaQueries.phone} {
        padding-top: 4rem;
    }
`;
